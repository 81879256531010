// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-busqueda-js": () => import("./../../../src/pages/busqueda.js" /* webpackChunkName: "component---src-pages-busqueda-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-elecciones-2021-jsx": () => import("./../../../src/pages/elecciones2021.jsx" /* webpackChunkName: "component---src-pages-elecciones-2021-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-autor-js": () => import("./../../../src/templates/autor.js" /* webpackChunkName: "component---src-templates-autor-js" */),
  "component---src-templates-nota-js": () => import("./../../../src/templates/nota.js" /* webpackChunkName: "component---src-templates-nota-js" */),
  "component---src-templates-seccion-js": () => import("./../../../src/templates/seccion.js" /* webpackChunkName: "component---src-templates-seccion-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

